/* CSS Document */
@charset "utf-8";

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn_fahres {
  /* border: none; */
  border-radius: 2px;
  text-align: right;
  background-color: rgba(207, 219, 207, 0.358);
  border-color: hsla(91, 100%, 50%, 0.288);
  width: 50px;
  font-family: "Times New Roman";
  font-size: x-small; /*medium;*/
  font-weight: bolder;
}

.btn_safahat {
  /* border: none; */
  border-radius: 2px;
  text-align: right;
  background-color: rgba(207, 219, 207, 0.358);
  border-color: hsla(238, 100%, 50%, 0.137);
  width: 50px;
  font-family: "Times New Roman";
  font-size: medium;
  font-weight: bolder;
}

.list {
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  /*flex-grow: 20;*/
}

/* ---------------------------------------------------------------------------*/


body {
  background-color: white;
  color: green;
  font-weight: bold;
  /*font-family: "Arabic Typesetting";*/
  font-family: "Traditional Arabic", Serif;
  /*font-family: "Simplified Arabic",Serif; */
}

div {
  padding-right: 5px;
  padding-left: 5px;
}

table {
  border-collapse: collapse;
  background-color: #f0f0c8;
}

td,
th {
  color: Black;
  border-style: none;
  border: 1px solid red;
  max-width: 500px;
  /*border: 1px double green; */
  padding-right: 5px;
  padding-left: 5px;
}

th {
  text-align: center;
  background: rgb(249, 168, 168);
}

td {
  text-align: right;
  color: navy
}

tr:nth-child(even) {
  background: #f7f777
}

;

mark {
  background-color: inherit;
  color: rgb(80, 8, 214);
}


button {
  font-weight: bold;
  border-radius: 5px;
  border-color: red;
  border-width: thin;
  background-color: rgb(255, 240, 240);
}

/*button { border: none; background-color: transparent; }*/

img {
  height: 30px;
  width: 30px;
}


/*input {width:70px; font-weight:bold; color:red; text-align:center;} */
a {
  color: green;
}

a:hover {
  color: red;
}

a:visited {
  color: navy;
}

.highlight {
  /*background-color: transparent;*/
  color: red;
}

.rectangle {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: rgb(243, 230, 230);
}

