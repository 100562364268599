/* menu style */

/*nav { text-align: center; align-items: center;}*/

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(184, 241, 239, 0.965);
  align-items: center;
  text-align: center; 
  position:relative;
}

li { float: right; }

li a {
  /*display: block;*/
  /*color: white;*/
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: navy;
  color:white;
}

li a:active {
  background-color: red;
  color:white;
}