@font-face {
   font-family: 'KFGQPC HafsEx1 Uthmanic Script';
   src: local('KFGQPC Uthmanic Script HAFS'), url('../fonts/UthmanicHafs1 Ex1 Ver12.otf') format('opentype');
   font-weight: normal;
   font-style: normal;
 }
@font-face {
  font-family: 'KFGQPC WARSH Uthmanic Script';
  src: local('KFGQPC WARSH Uthmanic Script'), url('../fonts/UthmanicWarsh1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC BAZZI Uthmanic Script';
  src: local('KFGQPC BAZZI Uthmanic Script'), url('../fonts/UthmanicBazzi1 Ver06.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC DOORI Uthmanic Script';
  src: local('KFGQPC DOORI Uthmanic Script'), url('../fonts/UthmanicDoori1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC QALOON Uthmanic Script';
  src: local('KFGQPC QALOON Uthmanic Script'), url('../fonts/UthmanicQaloon1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC QUMBUL Uthmanic Script';
  src: local('KFGQPC QUMBUL Uthmanic Script'), url('../fonts/UthmanicQumbul1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC SHOUBA Uthmanic Script';
  src: local('KFGQPC SHOUBA Uthmanic Script'), url('../fonts/UthmanicShouba1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KFGQPC SOOSI Uthmanic Script';
  src: local('KFGQPC SOOSI Uthmanic Script'), url('../fonts/UthmanicSoosi1 Ver05.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'QURAN MADINA';
  src: local('QURAN MADINA'), url('../fonts/MADDINA.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*
   font-family:  "DejaVu Serif", 
   "STFangsong", "STHeiti", "STKaiti", "STSong", "AB AlBayan", "AB Geeza", "AB Kufi",
   "Aldhabi", "Andalus", "Sakkal Majalla", "Simplified Arabic", "Traditional Arabic", "Arabic Typesetting", "Urdu Typesetting",
   "Droid Naskh", "Droid Kufi", "Roboto", "Tahoma", "Times New Roman", "Arial", serif;
*/

  /* font-family: "Geeza Pro", serif; */
  /* font-family: "Nadeem"; */
  /* font-family: "Al Bayan"; */ 
  /* font-family: "DecoType Naskh"; */